import React, { Component, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyles from './styles/global';
import theme from './styles/theme';
import { Container, Content, Wrapper } from './styles/components';

import Header from './components/Header';
import Footer from './components/Footer';
import Routes from './routes';

class App extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Wrapper>
              <Container>
                <GlobalStyles />
                <Header />
                <Content>
                  <Routes />
                </Content>
                <Footer />
              </Container>
            </Wrapper>
          </ThemeProvider>
        </BrowserRouter>
      </Fragment>
    );
  }
}

export default App;
