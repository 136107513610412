import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeft,
  faLongArrowAltLeft,
  faLongArrowAltRight
} from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  SectionSeparator,
  SectionTitle,
  Content,
  Title,
  QuotationMarkContainer,
  Description,
  Author,
  CurrentAvatar,
  TopLeftAvatar,
  TopRightAvatar,
  LeftTopAvatar,
  LeftBottomAvatar,
  BottomLeftAvatar,
  BottomRightAvatar,
  AuthorOccupation,
  LeftArrowContainer,
  RightArrowContainer
} from "./styles";

import felipeAvatar from "../../../../assets/images/userReviews/felipe-omae.jpg";
import wellingtonAvatar from "../../../../assets/images/userReviews/wellington-martins.jpg";
import nataliaAvatar from "../../../../assets/images/userReviews/natalia-barbosa.jpg";
import henriqueAvatar from "../../../../assets/images/userReviews/henrique-feitosa.jpg";
import alefAvatar from "../../../../assets/images/userReviews/alef-rocha.jpg";
import giseleAvatar from "../../../../assets/images/userReviews/gisele-matias.jpg";

class Reviews extends PureComponent {
  state = {
    userReviews: [
      {
        avatar: nataliaAvatar,
        author: "Natalia Barbosa",
        authorOccupation: "",
        comment: `Aplicativo rápido e prático! Aprovado!!!"`
      },
      {
        avatar: felipeAvatar,
        author: "Felipe Omae",
        authorOccupation: "",
        comment: `Muito fácil de usar, ótimo aplicativo"`
      },
      {
        avatar: wellingtonAvatar,
        author: "Wellington Martins",
        authorOccupation: "",
        comment: `Ótimo app, muito prático e fácil de usar, parabéns!!!"`
      },
      {
        avatar: henriqueAvatar,
        author: "Henrique Feitosa",
        authorOccupation: "",
        comment: `Prático e rápido"`
      },
      {
        avatar: alefAvatar,
        author: "Alef Rocha",
        authorOccupation: "",
        comment: `Muito bom!"`
      },
      {
        avatar: giseleAvatar,
        author: "Giselle Matias",
        authorOccupation: "",
        comment: `Muito bom. [...]. Facilitou para estacionar e adquirir o bilhete..."`
      }
    ],
    currentSelectedIndex: 0
  };

  selectNextReview = () => {
    const { currentSelectedIndex, userReviews } = this.state;

    if (currentSelectedIndex + 1 > userReviews.length - 1) {
      this.setState(() => ({ currentSelectedIndex: 0 }));
    } else {
      this.setState(prevState => ({
        currentSelectedIndex: prevState.currentSelectedIndex + 1
      }));
    }
  };

  selectPreviousReview = () => {
    const { currentSelectedIndex, userReviews } = this.state;

    if (currentSelectedIndex - 1 < 0) {
      this.setState(() => ({ currentSelectedIndex: userReviews.length - 1 }));
    } else {
      this.setState(prevState => ({
        currentSelectedIndex: prevState.currentSelectedIndex - 1
      }));
    }
  };

  render() {
    return (
      <Container>
        <SectionSeparator />
        <SectionTitle>Avaliações</SectionTitle>
        <Content>
          <CurrentAvatar
            avatarImage={
              this.state.userReviews[this.state.currentSelectedIndex].avatar
            }
          />
          <TopLeftAvatar avatarImage={felipeAvatar} />
          <TopRightAvatar avatarImage={nataliaAvatar} />
          <LeftTopAvatar avatarImage={wellingtonAvatar} />
          <LeftBottomAvatar avatarImage={henriqueAvatar} />
          <BottomLeftAvatar avatarImage={alefAvatar} />
          <BottomRightAvatar avatarImage={giseleAvatar} />
          <Title>O que nossos usuários já disseram</Title>
          <QuotationMarkContainer>
            <FontAwesomeIcon icon={faQuoteLeft} size="3x" />
          </QuotationMarkContainer>
          <Description>
            {this.state.userReviews[this.state.currentSelectedIndex].comment}
          </Description>
          <Author>
            {this.state.userReviews[this.state.currentSelectedIndex].author}
          </Author>
          <AuthorOccupation>
            {
              this.state.userReviews[this.state.currentSelectedIndex]
                .authorOccupation
            }
          </AuthorOccupation>
          <LeftArrowContainer onClick={this.selectPreviousReview}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} size="2x" />
          </LeftArrowContainer>
          <RightArrowContainer onClick={this.selectNextReview}>
            <FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />
          </RightArrowContainer>
        </Content>
      </Container>
    );
  }
}

export default Reviews;
