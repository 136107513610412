import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  grid-template-areas:
    "download-app-section"
    "presentational-video-section"
    "how-to-section"
    "reviews-section";

  @media screen and (min-width: 640px) {
    grid-template-columns: 1fr;
    grid-row-gap: 64px;
    grid-template-areas:
      "download-app-section"
      "presentational-video-section"
      "how-to-section"
      "reviews-section";
  }
`;
