import React, { Fragment } from "react";
import { isIOS, isAndroid } from "react-device-detect";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

import { Container } from "./styles";
import DownloadButton from "./components/DownloadButton";
import iPhoneMock from "../../../../assets/images/first-view-mockup.png";

const DownloadApp = () => {
  const renderAppleStoreButton = () => {
    return (
      <DownloadButton
        icon={faApple}
        topText="Disponível na"
        bottomText="APPLE STORE"
        className={isIOS ? "button-highlight" : "button"}
        href="https://itunes.apple.com/br/app/faz-zona-azul-fortaleza/id1425293453"
      />
    );
  };

  const renderPlayStoreButton = () => {
    return (
      <DownloadButton
        icon={faGooglePlay}
        topText="Disponível na"
        bottomText="PLAY STORE"
        className={isAndroid ? "button-highlight" : "button"}
        href="https://play.google.com/store/apps/details?id=com.zona.azul.digital.cartao.azul.fortaleza.amc.estacionamento.rotativo"
      />
    );
  };

  return (
    <Container>
      <div className="download-app-info">
        <h1 className="app-info-title">
          A evolução em estacionamento rotativo
        </h1>
        <p className="app-info-description">
          Com o app FAZ Digital Fortaleza você tem comodidade e agilidade na
          hora de estacionar seu veículo nas ruas de Fortaleza.
        </p>
        {isAndroid ? (
          <Fragment>
            {renderPlayStoreButton()}
            {renderAppleStoreButton()}
          </Fragment>
        ) : (
          <Fragment>
            {renderAppleStoreButton()}
            {renderPlayStoreButton()}
          </Fragment>
        )}
      </div>

      <div className="download-app-mock">
        <img src={iPhoneMock} alt="iPhone mockup" />
      </div>
    </Container>
  );
};

export default DownloadApp;
