import React, { Fragment } from 'react';

import { Image, Title, Description } from './styles';
import parkingImage from '../../../../assets/images/parking.svg';

const FleetOwnerIntro = () => (
  <Fragment>
    <Image className="fleet-owner-intro-image" src={parkingImage} />
    <Title className="fleet-owner-intro-title">Seja um frotista</Title>
    <Description className="fleet-owner-intro-description">
      <p>
        O Estacionamento Rotativo Digital de Belo Horizonte permite que o
        usuário comum ative o Rotativo e/ou Bônus para até dois veículos por CPF
        ou CNPJ no mesmo dia.
      </p>
      <p>
        Caso você possua uma empresa, você pode ultrapassar esse limite se
        cadastrando no sistema de gestão de frotistas da BHTrans.
      </p>
    </Description>
  </Fragment>
);

export default FleetOwnerIntro;
